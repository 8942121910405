import { createBrowserRouter } from "react-router-dom";
import ProtectedRoutes from "./ProtectedRoutes";
import UnProtectedRoutes from "./UnProtectedRoutes";
import Login from "../pages/Login";
import DashBoard from "../pages/DashBoard";
import TicketDetails from "../pages/TicketDetails";
import AssignedTicketDetails from "../pages/AssignedTicketDetails";
import Admin from "../pages/Admin";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <UnProtectedRoutes>
        <Login />
      </UnProtectedRoutes>
    ),
  },
  {
    path: "/admin",
    element: (
      <ProtectedRoutes>
        <Admin />
      </ProtectedRoutes>
    ),
  },
  {
    path: "/dashboard",
    element: (
      <ProtectedRoutes>
        <DashBoard />
      </ProtectedRoutes>
    ),
  },
  {
    path: "/tickets/:id",
    element: (
      <ProtectedRoutes>
        <TicketDetails />
      </ProtectedRoutes>
    ),
  },
  {
    path: "/assigned/:id",
    element: (
      <ProtectedRoutes>
        <AssignedTicketDetails />
      </ProtectedRoutes>
    ),
  },
]);
export default router;
