import { useEffect, useState } from "react";
import { DebounceInput } from "react-debounce-input";
import { useAppDispatch, useAppSelector } from "../hooks/hooks";
import { getTicketLogsThunk } from "../redux/slices/ticketSlice";
import Header from "../components/common/Header";
import TicketLogTable from "../components/ticket/TicketLogTable";
import { GroupType } from "./AssignedTicketDetails";
import styles from "../styles/modules/Search.module.scss";

const Admin = () => {
  const limit = 5;
  const dispatch = useAppDispatch();
  const [offset, setOffset] = useState<number>(0);
  const [searchText, setSearchText] = useState<string>("");
  const [type, setType] = useState<string>("");
  const [status, setStatus] = useState<string>("");
  const { isLoadMoreTicketLogs, ticketLogs } = useAppSelector((s) => s.tickets);

  useEffect(() => {
    dispatch(getTicketLogsThunk(offset, limit, type, status, searchText));
  }, [type, status, searchText, offset]);

  const types = ["", "it", "admin", "hr", "finance"];
  const ticketType = {
    all: "All",
    it: "IT",
    admin: "Admin",
    hr: "HR",
    finance: "Finance",
  };

  return (
    <div>
      <Header />
      <div className="container margin-bottom-ticket">
        <div className="d-flex flex-row justify-content-between align-items-center mt-top m-bottom-search">
          <div className={styles.inputTextField}>
            <DebounceInput
              type="text"
              className={styles.input}
              placeholder="Search"
              minLength={2}
              debounceTimeout={500}
              onChange={(event) => {
                setSearchText(event.target.value);
                setOffset(0);
              }}
            />
            <img
              style={{ width: "20px", height: "20px" }}
              src="/search.svg"
            ></img>
          </div>
          <div className="d-flex flex-row justify-content-between align-items-center filter-container">
            <div className="d-flex flex-row align-items-center filter-container">
              <span className="reply">Type:</span>
              <div className="filter-type-container">
                <select
                  className="input-comment no-background"
                  value={type}
                  onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                    setType(event.target.value);
                    setOffset(0);
                  }}
                >
                  {types.map((type: string) => {
                    return (
                      <option value={type} key={type}>
                        {ticketType[type as GroupType]}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="d-flex flex-row align-items-center filter-container">
              <span className="reply">Status:</span>
              <div className="filter-type-container">
                <select
                  className="input-comment no-background"
                  value={status}
                  onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                    setStatus(event.target.value);
                    setOffset(0);
                  }}
                >
                  <option value="">All</option>
                  <option value="open">Open</option>
                  <option value="inprogress">In-progress</option>
                  <option value="onhold">On-hold</option>
                  <option value="solved">Solved</option>
                  <option value="rejected">Rejected</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <TicketLogTable tickets={ticketLogs} />
        {isLoadMoreTicketLogs ? (
          <div className="d-flex justify-content-center load-more-container">
            <button
              className="load-more"
              onClick={() => setOffset((prev) => prev + 1)}
            >
              <span className="load-more-text">Load more</span>
            </button>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Admin;
