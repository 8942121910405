import client from "../../httpClient";
import { ServerResponse } from "../../helpers/core";
import {
  TicketDetailsResponse,
  AddTicketResponse,
  AddCommentResponse,
  AddTicketFormData,
  UpdateTicketResposne,
  UpdateTicketData,
  AddCommentFormData,
  CommentDetailsResponse,
  TicketLogResponse,
} from "../../types/Ticket.types";
import { TicketResponse } from "../../types/Dashboard.types";

export const getTickets = async (
  search?: string
): Promise<ServerResponse<TicketResponse>> => {
  try {
    const response = await client.get<TicketResponse>(
      search ? `/tickets?search=${search}` : `/tickets`
    );
    return {
      key: "success",
      data: response.data,
    };
  } catch (error) {
    return {
      key: "error",
      error: "failed to fetch tickets",
    };
  }
};

export const getAssignedTickets = async (
  offset: number,
  limit: number,
  search: string | null,
  status: string | null
): Promise<ServerResponse<TicketResponse>> => {
  try {
    let url = `/tickets/assigned/tickets?offset=${offset}&limit=${limit}`;
    if (status) {
      url = url + `&status=${status}`;
    }
    if (search) {
      url = url + `&search=${search}`;
    }
    const response = await client.get<TicketResponse>(url);
    return {
      key: "success",
      data: response.data,
    };
  } catch (error) {
    return {
      key: "error",
      error: "failed to fetch tickets",
    };
  }
};

export const getTicketDetails = async (
  ticketId: string
): Promise<ServerResponse<TicketDetailsResponse>> => {
  try {
    const response = await client.get<TicketDetailsResponse>(
      `/tickets/${ticketId}`
    );
    if (response.status === 403) {
      return {
        key: "error",
        error: response.data.error,
      };
    }
    return {
      key: "success",
      data: response.data,
    };
  } catch (error) {
    return {
      key: "error",
      error: "failed to fetch ticket",
    };
  }
};

export const updateTicket = async (
  ticketId: string,
  data: UpdateTicketData
): Promise<ServerResponse<UpdateTicketResposne>> => {
  try {
    const response = await client.put<UpdateTicketResposne>(
      `/tickets/${ticketId}`,
      data
    );
    if (response.status === 403) {
      return {
        key: "error",
        error: response.data.error,
      };
    }
    return {
      key: "success",
      data: response.data,
    };
  } catch (error) {
    return {
      key: "error",
      error: "failed to fetch ticket",
    };
  }
};

export const addTicket = async (
  data: AddTicketFormData
): Promise<ServerResponse<AddTicketResponse>> => {
  try {
    const response = await client.post<AddTicketResponse>("/tickets", data);
    if (response.status === 201) {
      return {
        key: "success",
        data: response.data,
      };
    }
    return {
      key: "error",
      error: response.data.error,
    };
  } catch (error) {
    return {
      key: "error",
      error: "failed to add ticket",
    };
  }
};

export const getCommentDetails = async (
  commentId: string
): Promise<ServerResponse<CommentDetailsResponse>> => {
  try {
    const response = await client.get<CommentDetailsResponse>(
      `/comments/${commentId}`
    );
    if (response.status === 403) {
      return {
        key: "error",
        error: response.data.error,
      };
    }
    return {
      key: "success",
      data: response.data,
    };
  } catch (error) {
    return {
      key: "error",
      error: "failed to fetch ticket",
    };
  }
};

export const addComment = async (
  data: AddCommentFormData
): Promise<ServerResponse<AddCommentResponse>> => {
  try {
    const response = await client.post<AddCommentResponse>("/comments", data);
    if (response.status === 201) {
      return {
        key: "success",
        data: response.data,
      };
    }
    return {
      key: "error",
      error: response.data.message,
    };
  } catch (error) {
    return {
      key: "error",
      error: "failed to add comment",
    };
  }
};

export const getTicketLogs = async (
  offset: number,
  limit: number,
  type?: string,
  status?: string,
  search?: string
): Promise<ServerResponse<TicketLogResponse>> => {
  let url = `/tickets/all/logs?offset=${offset}&limit=${limit}`;
  if (type) {
    url = url + `&type=${type}`;
  }
  if (status) {
    url = url + `&status=${status}`;
  }
  if (search) {
    url = url + `&search=${search}`;
  }
  try {
    const response = await client.get<TicketLogResponse>(url);
    if (response.status === 403) {
      return {
        key: "error",
        error: response.data.error,
      };
    }
    return {
      key: "success",
      data: response.data,
    };
  } catch (error) {
    return {
      key: "error",
      error: "failed to fetch ticket logs",
    };
  }
};
