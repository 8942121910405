import { AnyAction, configureStore, ThunkAction } from "@reduxjs/toolkit";
import appReducer from "../slices/appSlice";
import ticketReducer from "../slices/ticketSlice";
import userReducer from "../slices/userSlice";

const store = configureStore({
  reducer: {
    app: appReducer,
    tickets: ticketReducer,
    user: userReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  AnyAction
>;
export default store;
