import { useAppSelector } from "../hooks/hooks";
import TabHeader from "../components/common/TabHeader";
import Header from "../components/common/Header";
import MyTickets from "../components/dashboard/MyTickets";
import AssignedTickets from "../components/dashboard/AssignedTickets";

const DashBoard = () => {
	const { currentTab } = useAppSelector((s) => s.app);
	const { user } = useAppSelector((s) => s.user);

	console.log(user);

	return (
		<>
			{" "}
			<Header />
			<TabHeader />
			<div className="container margin-bottom-ticket">
				{currentTab === "Dashboard" && user.role !== "employee" ? (
					<AssignedTickets />
				) : (
					<MyTickets />
				)}
			</div>
		</>
	);
};

export default DashBoard;
