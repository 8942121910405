import { toast } from "react-toastify";

const showToaster = (toasterType: string, toasterMessage: string) => {
  switch (toasterType.toLowerCase()) {
    case "success":
      toast.success(toasterMessage, {
        position: toast.POSITION.TOP_RIGHT,
      });
      break;

    case "warn":
      toast.warn(toasterMessage, {
        position: toast.POSITION.TOP_RIGHT,
      });
      break;

    case "info":
      toast.info(toasterMessage, {
        position: toast.POSITION.TOP_RIGHT,
      });
      break;

    case "error":
      toast.error(toasterMessage, {
        position: toast.POSITION.TOP_RIGHT,
      });
      break;

    default:
      toast(toasterMessage, {
        position: toast.POSITION.TOP_RIGHT,
      });
      break;
  }
};

export default showToaster;
