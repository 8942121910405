import { useNavigate, useSearchParams } from "react-router-dom";
import moment from "moment";
import { useAppDispatch } from "../../hooks/hooks";
import { Ticket } from "../../types/Dashboard.types";

type Props = {
  tickets: Array<Ticket>;
};

const AssignedTicketTable = ({ tickets }: Props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const statusClass = {
    open: "open",
    solved: "solved",
    inprogress: "inProgress",
    onhold: "onhold",
    rejected: "rejected",
  };

  const statusText = {
    open: "Open",
    solved: "Solved",
    inprogress: "In Progress",
    onhold: "Onhold",
    rejected: "Rejected",
  };

  const typeText = {
    it: "IT",
    hr: "HR",
    admin: "Admin",
    finance: "Finance",
  };

  const redirect = (id: string) => {
    searchParams.set("redirect", "true");
    setSearchParams(searchParams);
    navigate(`/assigned/${id}`);
  };

  return (
    <table>
      <thead>
        <tr className="tr">
          <th className="table2Row">Requester</th>
          <th className="table2Row">Request</th>
          <th className="table2Row">Type</th>
          <th className="table2Row">Date</th>
          <th className="table2Row">Status</th>
        </tr>
      </thead>
      <tbody>
        {tickets.map((ticket) => {
          return (
            <tr key={ticket.id} onClick={() => redirect(ticket.id)}>
              <td className="table2Row">
                <div className="d-flex flex-row align-items-center">
                  <div className="profile-bg-ticket">
                    <span className="initial">
                      {ticket.user.firstName.charAt(0)}
                      {ticket.user.lastName.charAt(0)}
                    </span>
                  </div>
                  <span style={{ marginLeft: 12 }}>
                    {ticket.user.firstName} {ticket.user.lastName}
                  </span>
                </div>
              </td>
              <td className="table2Row">{ticket.title}</td>
              <td className="table2Row">{typeText[ticket.type]}</td>
              <td className="table2Row">
                <span className="date">
                  {moment(ticket.createdAt).format("D MMM YYYY")}
                </span>
              </td>
              <td className="table2Row">
                <span className={statusClass[ticket.status]}>
                  {statusText[ticket.status]}
                </span>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default AssignedTicketTable;
