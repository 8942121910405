import moment from "moment";
import { TicketLog, Log } from "../../types/Ticket.types";

type Props = {
  tickets: Array<TicketLog>;
};

type StatusType = "open" | "rejected" | "onhold" | "inprogress" | "solved";

const TicketLogTable = ({ tickets }: Props) => {
  const statusClass = {
    open: "open",
    solved: "solved",
    inprogress: "inProgress",
    onhold: "onhold",
    rejected: "rejected",
  };

  const statusText = {
    open: "Open",
    solved: "Solved",
    inprogress: "In Progress",
    onhold: "Onhold",
    rejected: "Rejected",
  };

  const typeText = {
    it: "IT",
    hr: "HR",
    admin: "Admin",
    finance: "Finance",
  };

  const logDescription = (log: Log): string => {
    if (log.actionType === "Create") {
      return `${log.actionBy.firstName} ${
        log.actionBy.lastName
      } created this ticket on ${moment(log.createdAt).format(
        "D MMM'YY hh:mm a"
      )}`;
    }
    if (log.actionType === "ChangeStatus") {
      return `${log.actionBy.firstName} ${
        log.actionBy.lastName
      } changed status to ${statusText[log.statusTo]} on ${moment(
        log.createdAt
      ).format("D MMM'YY hh:mm a")}`;
    }
    if (log.actionType === "ChangeAssignee") {
      return `${log.actionBy.firstName} ${log.actionBy.lastName} assigned to
          ${log.reassignedUserTo.firstName} ${
        log.reassignedUserTo.lastName
      } on ${moment(log.createdAt).format("D MMM'YY hh:mm a")}`;
    }
    if (log.actionType === "ChangeGroup") {
      return `${log.actionBy.firstName} ${
        log.actionBy.lastName
      } changed ticket group to ${typeText[log.reassignedGroupTo]} on ${moment(
        log.createdAt
      ).format("D MMM'YY hh:mm a")}`;
    }
    return "";
  };

  return (
    <table>
      <thead>
        <tr className="tr">
          <th className="table3Row">Requester</th>
          <th className="table3Row">Request</th>
          <th className="table3Row">Date</th>
          <th className="table3Row">Current status</th>
          <th className="table3Row">Logs</th>
        </tr>
      </thead>
      <tbody>
        {tickets.map((ticket) => {
          return (
            <tr key={ticket.id}>
              <td className="table3Row">
                <div className="d-flex flex-row align-items-center">
                  <span>
                    {ticket.user.firstName} {ticket.user.lastName}
                  </span>
                </div>
              </td>
              <td className="table3Row">{ticket.title}</td>
              <td className="table3Row">
                <span className="date">
                  {moment(ticket.createdAt).format("D MMM YYYY")}
                </span>
              </td>
              <td className="table3Row">
                <span className={statusClass[ticket.status as StatusType]}>
                  {statusText[ticket.status as StatusType]}
                </span>
              </td>
              <td className="table3Row">
                {ticket.logs.map((log: Log) => {
                  return (
                    <div className="ticket-log-ticket-steps" key={log.id}>
                      <span>
                        {logDescription(log)}
                        <br />
                      </span>
                    </div>
                  );
                })}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default TicketLogTable;
