import { createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "../store/store";
import { getUser } from "../services/userService";
import showToaster from "../../components/common/Toaster";
import { User } from "../../types/User.types";

export const getUserThunk = (): AppThunk<Promise<void>> => async (dispatch) => {
	const response = await getUser();
	switch (response.key) {
		case "success":
			dispatch(setUser(response.data.data.user));
			break;
		case "error": {
			showToaster("error", response.error);
			break;
		}
	}
};

interface UserState {
	user: User;
}

const initialState: UserState = {
	user: {
		id: "",
		email: "",
		firstName: "",
		lastName: "",
		role: "",
		groupMembers: [],
		createdAt: "",
		updatedAt: "",
		status: "",
	},
};

const userSlice = createSlice({
	name: "user",
	initialState,
	reducers: {
		setUser: (state, action) => {
			state.user = action.payload;
		},
	},
});

export const { setUser } = userSlice.actions;
export default userSlice.reducer;
