import { useState, useEffect } from "react";
import { DebounceInput } from "react-debounce-input";
import { useAppDispatch } from "../../hooks/hooks";
import { getTicketsThunk } from "../../redux/slices/ticketSlice";
import styles from "../../styles/modules/Search.module.scss";

type Props = {
  searchType: "myTickets" | "assignedTickets";
};

const SearchText = ({ searchType }: Props) => {
  const dispatch = useAppDispatch();
  const [searchText, setSearchText] = useState<string>("");

  useEffect(() => {
    dispatch(getTicketsThunk(searchText));
  }, [searchText]);

  return (
    <div className={styles.inputTextField}>
      <DebounceInput
        type="text"
        className={styles.input}
        placeholder="Search"
        minLength={2}
        debounceTimeout={500}
        onChange={(event) => {
          setSearchText(event.target.value);
        }}
      />
      <img style={{ width: "20px", height: "20px" }} src="/search.svg"></img>
    </div>
  );
};

export default SearchText;
