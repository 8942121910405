import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import { getUserThunk } from "../../redux/slices/userSlice";
import styles from "../../styles/modules/Header.module.scss";
import classNames from "classnames";

const Header = () => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const { user } = useAppSelector((s) => s.user);

	const logout = () => {
		localStorage.removeItem("token");
		navigate("/");
	};

	useEffect(() => {
		(async () => {
			await dispatch(getUserThunk());
		})();
	}, []);

	return (
		<div className="container">
			<div className="d-flex flex-row justify-content-between align-items-center header-padding">
				<div className="d-flex flex-row align-items-center">
					<img src="/logo.svg"></img>
				</div>
				<div className="d-flex flex-row align-items-center">
					{/* <div className="d-flex flex-row align-items-center">
            <img
              style={{ width: "14px", height: "14px" }}
              src="/settings.svg"
            />
            <span className={styles.settings}>Settings</span>
          </div> */}
					<div className={styles.profile}>
						<span className={styles.name}>{user.firstName}</span>
						<div className={styles.profileImage}>
							<img
								style={{ width: "12px", height: "12px" }}
								src="/Profile.svg"
							/>
						</div>
					</div>

					<button
						className={classNames(styles.logout, "d-flex")}
						onClick={logout}
					>
						<img style={{ width: "12px", height: "20px" }} src="/logout.svg" />
						<p
							className={styles.name}
							style={{
								marginLeft: "10px",
							}}
						>
							Logout
						</p>
					</button>
				</div>
			</div>
		</div>
	);
};

export default Header;
