import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";

const Login = () => {
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();

	useEffect(() => {
		const id_token = searchParams.get("id_token");
		if (id_token !== null) {
			localStorage.setItem("token", id_token);
			navigate("/dashboard");
		}
	}, [searchParams]);

	return (
		<div className="main-container">
			<div className="login-container">
				<div className="logo-container">
					<img style={{ width: 80, height: 80 }} src="/timesheets_logo.svg" />
					<span className="logo-text">Recktify</span>
				</div>
				<a
					className="login"
					href={`${process.env.REACT_APP_BASE_URL}/api/v1/msal/login`}
					// href={`${process.env.REACT_APP_BASE_URL}/api/v1/auth/google-redirect`}
					// href={`http://ec2-43-204-10-3.ap-south-1.compute.amazonaws.com/login?redirect_app_url=https://recktify.reckonsys.com`}
				>
					<span className="login-text">Login with Microsoft</span>
				</a>
			</div>
		</div>
	);
};

export default Login;
