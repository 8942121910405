import React, { useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import { getCommentDetailsThunk } from "../../redux/slices/ticketSlice";
import Loader from "../../components/common/Loader";

type Props = {
	setOpenAttachment: React.Dispatch<React.SetStateAction<boolean>>;
	src?: string;
	commentId?: string;
};

const Attachment = ({ setOpenAttachment, src, commentId }: Props) => {
	const dispatch = useAppDispatch();
	const { comment } = useAppSelector((s) => s.tickets);
	const [isLoading, setIsLoading] = useState<boolean>(true);

	useEffect(() => {
		(async () => {
			commentId && (await dispatch(getCommentDetailsThunk(commentId)));
			setIsLoading(false);
		})();
	}, [commentId]);

	return (
		<div className="modalImageContainer">
			<span
				className="modalImageClose"
				title="Close Modal"
				onClick={() => setOpenAttachment(false)}
			>
				×
			</span>
			<div className="modalContentImage">
				{isLoading ? (
					<Loader />
				) : (
					<img
						style={{ width: "100%" }}
						src={src ? src : comment.attachments}
					/>
				)}
			</div>
		</div>
	);
};

export default Attachment;
