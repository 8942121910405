import { useEffect, useState, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import moment from "moment";
import { useAppDispatch, useAppSelector } from "../hooks/hooks";
import {
  getTicketDetailsThunk,
  addCommentThunk,
  updateTicketThunk,
} from "../redux/slices/ticketSlice";
import Header from "../components/common/Header";
import Attachment from "../components/ticketDetails/Attachment";
import { StatusText } from "../components/ticket/TicketTable";
import Loader from "../components/common/Loader";
import TicketSteps from "../components/ticket/TicketSteps";
import showToaster from "../components/common/Toaster";
import { AddCommentFormData } from "../types/Ticket.types";

type StatusType = "open" | "rejected" | "onhold" | "inprogress" | "solved";
export type GroupType = "it" | "hr" | "admin" | "finance";

const AssignedTicketDetails = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const attachmentRef = useRef<HTMLInputElement>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [commentText, setCommentText] = useState<string>("");
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [openAttachment, setOpenAttachment] = useState<boolean>(false);
  const [openCommentAttachment, setOpenCommentAttachment] =
    useState<boolean>(false);
  const [commentId, setCommentId] = useState<string>("");
  const { ticket, groupMembers } = useAppSelector((s) => s.tickets);
  const { user } = useAppSelector((s) => s.user);
  const [status, setStatus] = useState<StatusType>("open");
  const [assignee, setAssignee] = useState<string>("");
  const [group, setGroup] = useState<string>("");
  const types = ["it", "admin", "hr", "finance"];

  const statusClass = {
    open: "open-comment",
    solved: "solved-comment",
    inprogress: "inProgress-comment",
    onhold: "onhold-comment",
    rejected: "rejected-comment",
  };

  const ticketType = {
    it: "IT",
    admin: "Admin",
    hr: "HR",
    finance: "Finance",
  };

  const handleFileUpload = (evt: React.ChangeEvent<HTMLInputElement>) => {
    if (evt.target.files != null) {
      setSelectedFile(evt.target.files[0]);
    }
  };

  useEffect(() => {
    (async () => {
      if (id) {
        await dispatch(getTicketDetailsThunk(id));
        setIsLoading(false);
      }
    })();
  }, [id]);

  useEffect(() => {
    setStatus(ticket.status);
  }, [ticket.status]);

  useEffect(() => {
    setGroup(ticket.type);
  }, [ticket.type]);

  useEffect(() => {
    setAssignee(ticket.assigneeId);
  }, [ticket.assigneeId]);

  const addComment = async () => {
    if (id) {
      if (selectedFile && selectedFile?.size > 5000000) {
        showToaster("error", "Attachemnt must be less then 5 MB.");
        return;
      }
      updateTicket();
      const formData: AddCommentFormData = new FormData();
      formData.append("ticketId", id);
      formData.append("text", commentText);
      selectedFile && formData.append("file", selectedFile);
      await dispatch(addCommentThunk(formData));
      setCommentText("");
      setSelectedFile(null);
    }
  };

  const updateTicket = async () => {
    let updateData = {};
    if (status !== ticket.status) {
      updateData = { ...updateData, status };
    }
    if (assignee !== ticket.assigneeId) {
      updateData = { ...updateData, assigneeId: assignee };
    }
    if (group !== ticket.type) {
      updateData = { ...updateData, type: group };
    }
    const response: boolean = await dispatch(
      updateTicketThunk(id as string, updateData)
    );
    if (response) {
      if (
        user.groupMembers
          .map((groupMember) => groupMember.group.name)
          .includes(group)
      ) {
        dispatch(getTicketDetailsThunk(id as string));
      } else {
        navigate(-1);
      }
    }
  };

  return (
    <>
      <Header />
      {isLoading ? (
        <Loader />
      ) : (
        <div className="container ticket-container">
          <div className="d-flex flex-row align-items-center justify-space-between mt-details">
            <div
              className="left-container"
              style={{ cursor: "pointer" }}
              onClick={() => navigate(-1)}
            >
              <img src="/Back.svg" />
            </div>
            <span className="back-text">Back to tickets </span>
          </div>
          <aside style={{ float: "right" }}>
            <TicketSteps logs={ticket?.logs} />
          </aside>
          <div className="d-flex">
            <div className="left-container" />
            <div className="d-flex flex-column response-width">
              <div className="d-flex flex-row align-items-center">
                <span className="response-query">{ticket?.title}</span>
                <span className={statusClass[ticket?.status]}>
                  {StatusText[ticket?.status]}
                </span>
              </div>
              <div className="d-flex flex-row align-items-center">
                <span className="response-type">
                  {ticket?.user.firstName} {ticket?.user.lastName}
                </span>
                <span className="response-date">
                  {ticket?.createdAt !== "" &&
                    moment(ticket?.createdAt).format("D MMM YYYY")}
                </span>
              </div>
            </div>
          </div>
          <div className="d-flex">
            <div className="left-container" />
            <div className="query-container">
              <p className="query">Query</p>
            </div>
          </div>
          <div className="d-flex">
            <div className="left-container" />
            <div className="summary-container">
              <p className="ml-response-query">{ticket?.summary}</p>
            </div>
          </div>
          {ticket?.attachments ? (
            <>
              <div className="d-flex">
                <div className="left-container" />
                <div className="attachment-container">
                  <p className="attachment">Attachment</p>
                </div>
              </div>
              <div className="d-flex">
                <div className="left-container" />
                <div
                  className="attachment-block"
                  onClick={() => setOpenAttachment(true)}
                >
                  <img
                    style={{ width: 18, height: 18 }}
                    src="/attachment.svg"
                  />
                  <p className="attachment-name">Attachment</p>
                </div>
              </div>
            </>
          ) : null}
          <div className="response-reply">
            {ticket.comments.map((comment) => {
              return (
                <div key={comment.id} className="response-container">
                  <div className="d-flex flex-row align-items-center">
                    <div className="left-container">
                      <div className="profile-bg">
                        <span className="initial">
                          {comment.user.firstName.charAt(0)}
                          {comment.user.lastName.charAt(0)}
                        </span>
                      </div>
                    </div>
                    <div>
                      <p className="user-name">{`${comment.user.firstName} ${comment.user.lastName}`}</p>
                    </div>
                  </div>
                  <div className="d-flex flex-row align-items-center">
                    <div className="left-container" />
                    <p className="user-comment">{comment.text}</p>
                  </div>
                  <div className="d-flex flex-row align-items-center">
                    <div className="left-container" />
                    {comment.attachments && (
                      <div className="d-flex ">
                        <div
                          className="attachment-block"
                          onClick={() => {
                            setCommentId(comment.id);
                            setOpenCommentAttachment(true);
                          }}
                        >
                          <img
                            style={{ width: 18, height: 18 }}
                            src="/attachment.svg"
                          />
                          <p className="attachment-name">Attachment</p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
            <div className="d-flex flex-row align-items-center reply-container">
              <div className="left-container">
                <span className="reply">Assign to </span>
              </div>
              <div className="status-container">
                <select
                  className="input-comment"
                  value={assignee}
                  onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
                    setAssignee(event.target.value)
                  }
                >
                  {groupMembers.map((groupMember) => {
                    return (
                      <option
                        value={groupMember.userId}
                        key={groupMember.id}
                      >{`${groupMember.user.firstName} ${groupMember.user.lastName}`}</option>
                    );
                  })}
                </select>
              </div>
              <div style={{ marginLeft: 90 }}>
                <span className="reply">Push to</span>
              </div>
              <div className="status-container ml-response">
                <select
                  className="input-comment"
                  value={group}
                  onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
                    setGroup(event.target.value as GroupType)
                  }
                >
                  {types.map((type: string) => {
                    return (
                      <option value={type} key={type}>
                        {ticketType[type as GroupType]}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="d-flex flex-row align-items-center reply-container">
              <div className="left-container">
                <span className="reply">Status</span>
              </div>
              <div className="status-container">
                <select
                  className="input-comment"
                  value={status}
                  onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
                    setStatus(event.target.value as StatusType)
                  }
                >
                  <option value="open">Open</option>
                  <option value="inprogress">In-progress</option>
                  <option value="onhold">On-hold</option>
                  <option value="solved">Solved</option>
                  <option value="rejected">Rejected</option>
                </select>
              </div>
            </div>
            <div className="d-flex flex-row reply-container">
              <div className="left-container">
                <span className="reply">Reply</span>
              </div>
              <div style={{ width: "100%" }}>
                <div className="comment-container">
                  <textarea
                    className="input-comment"
                    value={commentText}
                    onChange={(e) => setCommentText(e.target.value)}
                  />
                </div>
                {commentText.length < 6 && (
                  <span className="input-hint">minimum 6 character</span>
                )}
              </div>
            </div>
            <div className="d-flex flex-row mt-top-ticket">
              <div className="left-container" />
              <div>
                <div className="d-flex flex-row align-items-center">
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => attachmentRef?.current?.click()}
                  >
                    <img src="/upload.svg" />
                  </div>
                  <span className="responder">{selectedFile?.name}</span>
                </div>
                <input
                  type="file"
                  accept="image/*"
                  ref={attachmentRef}
                  onChange={handleFileUpload}
                  style={{ display: "none" }}
                />
              </div>
            </div>
            <div className="comment-submit ">
              <button
                className="ticket-submit d-flex align-self-end"
                onClick={addComment}
                disabled={commentText.length < 6}
              >
                <div className="d-flex flex-row align-items-center">
                  <img src="/submit.svg" />
                  <span className="submit">Submit</span>
                </div>
              </button>
            </div>
          </div>
          {openAttachment && (
            <Attachment
              setOpenAttachment={setOpenAttachment}
              src={ticket?.attachments}
            />
          )}
          {openCommentAttachment && (
            <Attachment
              setOpenAttachment={setOpenCommentAttachment}
              commentId={commentId}
            />
          )}
        </div>
      )}
    </>
  );
};

export default AssignedTicketDetails;
