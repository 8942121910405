import { Ticket } from "../../types/Dashboard.types";
import { useNavigate } from "react-router-dom";
import moment from "moment";

type Props = {
  tickets: Array<Ticket>;
};

export const StatusText = {
  open: "Open",
  solved: "Solved",
  inprogress: "In Progress",
  onhold: "Onhold",
  rejected: "Rejected",
};

const TicketTable = ({ tickets }: Props) => {
  const navigate = useNavigate();
  const statusClass = {
    open: "open",
    solved: "solved",
    inprogress: "inProgress",
    onhold: "onhold",
    rejected: "rejected",
  };

  const typeText = {
    it: "IT",
    hr: "HR",
    admin: "Admin",
    finance: "Finance",
  };

  const redirect = (id: string) => {
    navigate(`/tickets/${id}`);
  };

  return (
    <table>
      <thead>
        <tr className="tr">
          <th className="tableRow">Request</th>
          <th className="tableRow">Type</th>
          <th className="tableRow">Date</th>
          <th className="tableRow">Status</th>
        </tr>
      </thead>
      <tbody>
        {tickets.map((ticket) => {
          return (
            <tr key={ticket.id} onClick={() => redirect(ticket.id)}>
              <td className="tableRow">{ticket.title}</td>
              <td className="tableRow">{typeText[ticket.type]}</td>
              <td className="tableRow">
                <span className="date">
                  {moment(ticket.createdAt).format("D MMM YYYY")}
                </span>
              </td>
              <td className="tableRow">
                <span className={statusClass[ticket.status]}>
                  {StatusText[ticket.status]}
                </span>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default TicketTable;
