import classNames from "classnames";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import { setTab } from "../../redux/slices/appSlice";
import style from "../../styles/modules/TabHeader.module.scss";

const TabHeader = () => {
	const dispatch = useAppDispatch();
	const { currentTab } = useAppSelector((s) => s.app);
	const { user } = useAppSelector((s) => s.user);
	const types = ["it", "admin", "hr", "finance"];

	return (
		<div className={style.container}>
			<div className={classNames(style.tabs, "container")}>
				{user.groupMembers
					.map((groupMem) => groupMem.group.name)
					.some((group) => types.includes(group)) &&
					user.role !== "employee" && (
						<div
							style={{ marginRight: 80 }}
							onClick={() => dispatch(setTab("Dashboard"))}
						>
							<div
								className={classNames(
									style.tab,
									currentTab === "Dashboard" && style.tabBorder
								)}
							>
								<span className={style.tabtext}>Dashboard</span>
							</div>
						</div>
					)}
				<div
					className={classNames(
						style.tab,
						currentTab === "Tickets" ||
							(user.role === "employee" && style.tabBorder)
					)}
					onClick={() => dispatch(setTab("Tickets"))}
				>
					<span className={style.tabtext}>My Tickets</span>
				</div>
			</div>
		</div>
	);
};

export default TabHeader;
