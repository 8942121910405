import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import { getTicketsThunk } from "../../redux/slices/ticketSlice";
import SearchText from "../search/SearchText";
import AddTicket from "../../pages/AddTicket";
import TicketTable from "../ticket/TicketTable";
import Loader from "../../components/common/Loader";

const MyTickets = () => {
  const dispatch = useAppDispatch();
  const { tickets } = useAppSelector((s) => s.tickets);
  const [isLoading, setIsLoading] = useState<boolean>(tickets.length === 0);
  const [openAddTicket, setOpenAddTicket] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      await dispatch(getTicketsThunk());
      setIsLoading(false);
    })();
  }, []);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className="d-flex flex-row justify-content-between align-items-center mt-top">
            <SearchText searchType="myTickets" />
            <button className="request" onClick={() => setOpenAddTicket(true)}>
              <img
                style={{ width: "20px", height: "20px" }}
                src="/Request.svg"
              />
              <span className="request-text">Request</span>
            </button>
          </div>
          <span className="request-list">Request list </span>
          {tickets.length > 0 ? (
            <TicketTable tickets={tickets} />
          ) : (
            <div className="d-flex flex-column align-items-center">
              <img
                style={{ marginTop: "30px", width: "300px", height: "300px" }}
                src="/NoTickets.svg"
              />
              <p className="no-ticket">
                Please feel free to make a request if you have one. Currently,
                there are no requests in queue.
              </p>
            </div>
          )}
          {openAddTicket && <AddTicket setOpenAddTicket={setOpenAddTicket} />}
        </>
      )}
    </>
  );
};

export default MyTickets;
