import { createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "../store/store";
import {
	getTickets,
	getAssignedTickets,
	addTicket,
	getTicketDetails,
	addComment,
	updateTicket,
	getCommentDetails,
	getTicketLogs,
} from "../services/ticketService";
import {
	TicketDetails,
	AddTicketFormData,
	UpdateTicketData,
	AddCommentFormData,
	CommentDetails,
	GroupMember,
	TicketLog,
} from "../../types/Ticket.types";
import { Ticket } from "../../types/Dashboard.types";
import showToaster from "../../components/common/Toaster";

export const getTicketsThunk =
	(search?: string): AppThunk<Promise<void>> =>
	async (dispatch) => {
		const response = await getTickets(search);
		switch (response.key) {
			case "success":
				dispatch(setTickets(response.data.data.tickets));
				break;
			case "error": {
				showToaster("error", response.error);
				break;
			}
		}
	};

export const getAssignedTicketsThunk =
	(
		offset: number,
		limit: number,
		search: string | null,
		status: string | null
	): AppThunk<Promise<void>> =>
	async (dispatch, state) => {
		const response = await getAssignedTickets(offset, limit, search, status);
		switch (response.key) {
			case "success":
				if (offset > 0) {
					dispatch(
						setAssignedTickets([
							...state().tickets.assignedTickets,
							...response.data.data.tickets,
						])
					);
				} else {
					dispatch(setAssignedTickets(response.data.data.tickets));
				}
				dispatch(setIsLoadMoreAssignedTickets(response.data.data.loadMore));
				dispatch(setStatusCount(response.data.data.statusCount));
				break;
			case "error": {
				showToaster("error", response.error);
				break;
			}
		}
	};

export const addTicketThunk =
	(data: AddTicketFormData): AppThunk<Promise<void>> =>
	async (dispatch) => {
		const response = await addTicket(data);
		switch (response.key) {
			case "success":
				dispatch(getTicketsThunk());
				break;
			case "error": {
				showToaster("error", response.error);
				break;
			}
		}
	};

export const addCommentThunk =
	(data: AddCommentFormData): AppThunk<Promise<void>> =>
	async (dispatch, state) => {
		const response = await addComment(data);
		switch (response.key) {
			case "success":
				dispatch(
					setTicket({
						...state().tickets.ticket,
						comments: [
							...state().tickets.ticket.comments,
							{
								...response.data.data.comment,
								user: state().user.user,
							},
						],
					})
				);
				break;
			case "error": {
				showToaster("error", response.error);
				break;
			}
		}
	};

export const getTicketDetailsThunk =
	(ticketId: string): AppThunk<Promise<void>> =>
	async (dispatch) => {
		const response = await getTicketDetails(ticketId);
		switch (response.key) {
			case "success":
				dispatch(setTicket(response.data.data.ticket));
				dispatch(setGroupMembers(response.data.data.groupMembers));
				break;
			case "error": {
				showToaster("error", response.error);
				break;
			}
		}
	};

export const updateTicketThunk =
	(ticketId: string, data: UpdateTicketData): AppThunk<Promise<boolean>> =>
	async () => {
		const response = await updateTicket(ticketId, data);
		switch (response.key) {
			case "success":
				showToaster("success", "Ticket updated successfully");
				return true;
			case "error": {
				showToaster("error", response.error);
				return false;
			}
		}
	};

export const getCommentDetailsThunk =
	(commentId: string): AppThunk<Promise<void>> =>
	async (dispatch) => {
		const response = await getCommentDetails(commentId);
		switch (response.key) {
			case "success":
				dispatch(setComment(response.data.data.comment));
				break;
			case "error": {
				showToaster("error", response.error);
				break;
			}
		}
	};

export const getTicketLogsThunk =
	(
		offset: number,
		limit: number,
		type?: string,
		status?: string,
		search?: string
	): AppThunk<Promise<void>> =>
	async (dispatch, state) => {
		const response = await getTicketLogs(offset, limit, type, status, search);
		switch (response.key) {
			case "success":
				if (state().tickets.ticketLogOffset < offset) {
					dispatch(
						setTicketLogs([
							...state().tickets.ticketLogs,
							...response.data.data.tickets,
						])
					);
					dispatch(setTicketLogOffset(offset));
				} else {
					dispatch(setTicketLogs(response.data.data.tickets));
					dispatch(setTicketLogOffset(offset));
				}
				dispatch(setIsLoadMoreTicketLogs(response.data.data.loadMore));
				break;
			case "error": {
				showToaster("error", response.error);
				break;
			}
		}
	};

interface TicketState {
	tickets: Array<Ticket>;
	assignedTickets: Array<Ticket>;
	isLoadMoreAssignedTickets: boolean;
	ticket: TicketDetails;
	comment: CommentDetails;
	groupMembers: Array<GroupMember>;
	ticketLogs: Array<TicketLog>;
	isLoadMoreTicketLogs: boolean;
	ticketLogOffset: number;
	statusCount: object | any;
}

const initialState: TicketState = {
	tickets: [],
	assignedTickets: [],
	statusCount: {},
	isLoadMoreAssignedTickets: false,
	ticket: {
		status: "open",
		id: "",
		title: "",
		summary: "",
		type: "admin",
		attachments: "",
		userId: "",
		assigneeId: "",
		createdAt: "",
		updatedAt: "",
		comments: [],
		logs: [],
		user: { email: "", firstName: "", lastName: "", role: "", status: "" },
	},
	comment: {
		id: "",
		text: "",
		attachments: "",
		createdAt: "",
		updatedAt: "",
	},
	groupMembers: [],
	ticketLogs: [],
	isLoadMoreTicketLogs: false,
	ticketLogOffset: 0,
};

const ticketSlice = createSlice({
	name: "tickets",
	initialState,
	reducers: {
		setTickets: (state, action) => {
			state.tickets = action.payload;
		},
		setAssignedTickets: (state, action) => {
			state.assignedTickets = action.payload;
		},
		setIsLoadMoreAssignedTickets: (state, action) => {
			state.isLoadMoreAssignedTickets = action.payload;
		},
		setStatusCount: (state, action) => {
			state.statusCount = action.payload;
		},
		setTicket: (state, action) => {
			state.ticket = action.payload;
		},
		setComment: (state, action) => {
			state.comment = action.payload;
		},
		setGroupMembers: (state, action) => {
			state.groupMembers = action.payload;
		},
		setTicketLogs: (state, action) => {
			state.ticketLogs = action.payload;
		},
		setIsLoadMoreTicketLogs: (state, action) => {
			state.isLoadMoreTicketLogs = action.payload;
		},
		setTicketLogOffset: (state, action) => {
			state.ticketLogOffset = action.payload;
		},
	},
});

export const {
	setTickets,
	setAssignedTickets,
	setIsLoadMoreAssignedTickets,
	setStatusCount,
	setTicket,
	setComment,
	setGroupMembers,
	setTicketLogs,
	setIsLoadMoreTicketLogs,
	setTicketLogOffset,
} = ticketSlice.actions;
export default ticketSlice.reducer;
