import { useAppSelector } from "../../hooks/hooks";
import { Steps } from "rsuite";
import moment from "moment";
import { Log } from "../../types/Ticket.types";
import PencilSquareIcon from "@rsuite/icons/legacy/PencilSquare";
import "rsuite/dist/rsuite.min.css";

type Props = {
  logs: Array<Log>;
};

const TicketSteps = ({ logs }: Props) => {
  const { user } = useAppSelector((s) => s.user);

  const statusText = {
    open: "Open",
    solved: "Solved",
    inprogress: "In Progress",
    onhold: "Onhold",
    rejected: "Rejected",
  };

  const typeText = {
    it: "IT",
    hr: "HR",
    admin: "Admin",
    finance: "Finance",
  };

  const logDescription = (log: Log) => {
    const actionBy =
      user.id === log.actionById
        ? "You have"
        : `${log.actionBy.firstName} ${log.actionBy.lastName} has`;

    if (log.actionType === "Create") {
      return `${actionBy} created this ticket.`;
    }
    if (log.actionType === "ChangeStatus") {
      return `${actionBy} changed the status to ${statusText[log.statusTo]}`;
    }
    if (log.actionType === "ChangeAssignee") {
      return `${actionBy} assigned to ${log.reassignedUserTo.firstName} ${log.reassignedUserTo.lastName}`;
    }
    if (log.actionType === "ChangeGroup") {
      return `${actionBy} assigned the ticket to ${
        typeText[log.reassignedGroupTo]
      } group`;
    }
    return null;
  };

  return (
    <Steps current={logs.length} vertical style={styles}>
      {logs.map((log) => {
        return (
          <Steps.Item
            key={log.id}
            title={moment(log.createdAt).format("D MMM'YY hh:mm a")}
            description={logDescription(log)}
            icon={<PencilSquareIcon style={{ fontSize: 20 }} />}
          />
        );
      })}
    </Steps>
  );
};

export default TicketSteps;

const styles = {
  width: "200px",
  display: "inline-table",
  verticalAlign: "top",
};
