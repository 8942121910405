import React, { useState, useRef } from "react";
import { useAppDispatch } from "../hooks/hooks";
import { addTicketThunk } from "../redux/slices/ticketSlice";
import { AddTicketFormData } from "../types/Ticket.types";
import showToaster from "../components/common/Toaster";

type Props = {
  setOpenAddTicket: React.Dispatch<React.SetStateAction<boolean>>;
};

const AddTicket = ({ setOpenAddTicket }: Props) => {
  const dispatch = useAppDispatch();
  const attachmentRef = useRef<HTMLInputElement>(null);
  const [type, setType] = useState<string>("");
  const [subject, setSubject] = useState<string>("");
  const [query, setQuery] = useState<string>("");
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleFileUpload = (evt: React.ChangeEvent<HTMLInputElement>) => {
    if (evt.target.files != null) {
      setSelectedFile(evt.target.files[0]);
    }
  };

  const addTicket = async () => {
    if (selectedFile && selectedFile?.size > 5000000) {
      showToaster("error", "Attachemnt must be less then 5 MB.");
      return;
    }
    const formData: AddTicketFormData = new FormData();
    formData.append("type", type);
    formData.append("title", subject);
    formData.append("summary", query);
    selectedFile && formData.append("file", selectedFile);
    setIsLoading(true);
    await dispatch(addTicketThunk(formData));
    setIsLoading(false);
    setOpenAddTicket(false);
  };

  return (
    <div className="modalContainer">
      <div className="modalContent">
        <span className="modalHeading">Request</span>
        <span
          className="modalClose"
          title="Close Modal"
          onClick={() => setOpenAddTicket(false)}
        >
          ×
        </span>
        <div className="modalBody">
          <div className="d-flex justify-content-between align-items-center question-container">
            <span className="ticket-question">Type</span>
            <div className="d-flex flex-row justify-content-between align-items-center input-radio-container">
              <div className="d-flex align-items-center">
                <label
                  htmlFor="hr"
                  className="d-flex align-items-center cursor-pointer"
                >
                  <input
                    type="radio"
                    id="hr"
                    name="ticket_type"
                    value="hr"
                    className="radio-right-margin"
                    checked={type === "hr"}
                    onChange={(e) => setType(e.target.value)}
                  />
                  HR
                </label>
              </div>
              <div className="d-flex align-items-center">
                <label
                  htmlFor="finance"
                  className="d-flex align-items-center cursor-pointer"
                >
                  <input
                    type="radio"
                    id="finance"
                    name="ticket_type"
                    value="finance"
                    className="radio-right-margin"
                    checked={type === "finance"}
                    onChange={(e) => setType(e.target.value)}
                  />
                  Finance
                </label>
              </div>
              <div className="d-flex align-items-center">
                <label
                  htmlFor="it"
                  className="d-flex align-items-center cursor-pointer"
                >
                  <input
                    type="radio"
                    id="it"
                    name="ticket_type"
                    value="it"
                    className="radio-right-margin"
                    checked={type === "it"}
                    onChange={(e) => setType(e.target.value)}
                  />
                  IT
                </label>
              </div>
              <div className="d-flex align-items-center">
                <label
                  htmlFor="admin"
                  className="d-flex align-items-center cursor-pointer"
                >
                  <input
                    type="radio"
                    id="admin"
                    name="ticket_type"
                    value="admin"
                    className="radio-right-margin"
                    checked={type === "admin"}
                    onChange={(e) => setType(e.target.value)}
                  />
                  Admin
                </label>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-between align-items-center question-container">
            <span className="ticket-question">Subject</span>
            <div className="question-parent-container">
              <div className="input-ticket-container">
                <input
                  type="text"
                  className="input-ticket"
                  value={subject}
                  onChange={(e) => setSubject(e.target.value)}
                />
              </div>
              {subject.length < 6 && (
                <span className="input-hint">minimum 6 character</span>
              )}
            </div>
          </div>
          <div className="d-flex justify-content-between align-items-center question-container">
            <span className="ticket-question">Query</span>
            <div className="question-parent-container">
              <div className="input-ticket-container">
                <textarea
                  className="input-ticket text-area"
                  value={query}
                  onChange={(e) => setQuery(e.target.value)}
                />
              </div>
              {query.length < 12 && (
                <span className="input-hint">minimum 12 character</span>
              )}
            </div>
          </div>
          <div className="d-flex justify-content-between align-items-center question-container">
            <span className="ticket-question">Attachments</span>
            <div className="question-parent-container">
              <div className="input-ticket-container">
                <div
                  className="d-flex flex-row align-items-center"
                  style={{ cursor: "pointer" }}
                  onClick={() => attachmentRef?.current?.click()}
                >
                  <div>
                    <img src="/upload.svg" />
                  </div>
                  <span className="responder">{selectedFile?.name}</span>
                </div>
              </div>
              <input
                type="file"
                accept="image/*"
                ref={attachmentRef}
                onChange={handleFileUpload}
                style={{ display: "none" }}
              />
            </div>
          </div>
          {/* <div className="d-flex justify-content-between align-items-center mt-top-ticket">
            <h4>Assignee</h4>
            <div className="input-ticket-container">
              <input type="text" className="input-ticket" />
            </div>
          </div> */}
          <div className="d-flex align-self-end submit-margin ">
            <button
              className="ticket-submit"
              onClick={addTicket}
              disabled={
                isLoading ||
                type === "" ||
                subject.length < 6 ||
                query.length < 12
              }
            >
              <div className="d-flex flex-row align-items-center">
                <img src="/submit.svg" />
                <span className="submit">Submit</span>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddTicket;
