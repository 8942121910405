import axios, { AxiosRequestHeaders } from "axios";

const client = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}/api/v1`,
  responseType: "json",
});

client.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");
  if (token) {
    (config.headers as AxiosRequestHeaders).Authorization = `Bearer ${token}`;
  }
  return config;
});

client.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error?.response?.status === 401) {
      localStorage.clear();
      if (!window.location.pathname.includes("login")) {
        window.location.assign("/");
      }
    }
    return error?.response;
  }
);

export default client;
