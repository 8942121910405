import client from "../../httpClient";
import { ServerResponse } from "../../helpers/core";
import { UserResponse } from "../../types/User.types";

export const getUser = async (): Promise<ServerResponse<UserResponse>> => {
  try {
    const response = await client.get<UserResponse>("/user");
    return {
      key: "success",
      data: response.data,
    };
  } catch (error) {
    return {
      key: "error",
      error: "failed to fetch user",
    };
  }
};
