import { useEffect, useState } from "react";
import { DebounceInput } from "react-debounce-input";
import { useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import { getAssignedTicketsThunk } from "../../redux/slices/ticketSlice";
import AssignedTicketTable from "../ticket/AssignedTicketTable";
import Loader from "../../components/common/Loader";
import { Ticket } from "../../types/Dashboard.types";
import styles from "../../styles/modules/Search.module.scss";

const AssignedTickets = () => {
	const dispatch = useAppDispatch();
	const { assignedTickets, isLoadMoreAssignedTickets, statusCount } =
		useAppSelector((s) => s.tickets);
	const { user } = useAppSelector((s) => s.user);
	const [searchParams, setSearchParams] = useSearchParams();
	const [isLoading, setIsLoading] = useState<boolean>(
		assignedTickets.length === 0
	);
	const [isMyTicketsChecked, setIsMyTicketsChecked] = useState<boolean>(false);
	const [tickets, setTickets] = useState<Array<Ticket>>([]);
	const [searchText, setSearchText] = useState<string>("");
	const [status, setStatus] = useState<string>("");

	useEffect(() => {
		const offsetParam = searchParams.get("offset");
		const status = searchParams.get("status");
		const searchText = searchParams.get("searchText");
		const redirect = searchParams.get("redirect");
		(async () => {
			const offset = offsetParam ? +offsetParam : 0;
			if (redirect === "true") {
				setIsLoading(true);
				for (let i = 0; i <= offset; i++) {
					await dispatch(getAssignedTicketsThunk(i, 5, searchText, status));
				}
			} else {
				await dispatch(getAssignedTicketsThunk(offset, 5, searchText, status));
			}
			status && setStatus(status);
			searchText && setSearchText(searchText);
			setIsLoading(false);
		})();
	}, [
		searchParams.get("offset"),
		searchParams.get("status"),
		searchParams.get("searchText"),
		searchParams.get("redirect"),
	]);

	useEffect(() => {
		if (isMyTicketsChecked) {
			setTickets(
				assignedTickets.filter((ticket) => ticket.assigneeId === user.id)
			);
		} else {
			setTickets(assignedTickets);
		}
	}, [isMyTicketsChecked, assignedTickets]);

	const getCountForStatus = (key: string) =>
		statusCount.find((status: any) => status.status === key)?.count || 0;

	const statusChangeHandler = (key: string) => {
		setStatus(key);
		searchParams.set("offset", "0");
		if (status === key) {
			setStatus("");
			searchParams.delete("status");
		} else {
			searchParams.set("status", key);
		}
		searchParams.delete("redirect");
		setSearchParams(searchParams);
	};

	return (
		<>
			{isLoading ? (
				<Loader />
			) : (
				<>
					<div className="d-flex flex-row justify-content-between align-items-center mt-top">
						<div className={styles.inputTextField}>
							<DebounceInput
								type="text"
								className={styles.input}
								placeholder="Search"
								minLength={2}
								debounceTimeout={500}
								value={searchText}
								onChange={(event) => {
									setSearchText(event.target.value);
									searchParams.set("offset", "0");
									event.target.value.length > 0
										? searchParams.set("searchText", event.target.value)
										: searchParams.delete("searchText");
									searchParams.delete("redirect");
									setSearchParams(searchParams);
								}}
							/>
							<img
								style={{ width: "20px", height: "20px" }}
								src="/search.svg"
							></img>
						</div>
						<div className="d-flex align-items-center justify-content-between filter-container">
							<div className="d-flex flex-row align-items-center filter-container">
								<span className="reply">Status:</span>
								<div className="filter-type-container">
									<select
										className="input-comment no-background"
										value={status}
										onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
											setStatus(event.target.value);
											searchParams.set("offset", "0");
											event.target.value === ""
												? searchParams.delete("status")
												: searchParams.set("status", event.target.value);
											searchParams.delete("redirect");
											setSearchParams(searchParams);
										}}
									>
										<option value="">All</option>
										<option value="open">Open</option>
										<option value="inprogress">In-progress</option>
										<option value="onhold">On-hold</option>
										<option value="solved">Solved</option>
										<option value="rejected">Rejected</option>
									</select>
								</div>
							</div>
							<div className="d-flex align-items-center">
								<input
									type="checkbox"
									id="checkbox"
									style={{ marginRight: 6 }}
									className="checkbox-checked"
									checked={isMyTicketsChecked}
									onChange={() => setIsMyTicketsChecked(!isMyTicketsChecked)}
								/>
								<label htmlFor="checkbox" className="response-type">
									Show assigned to me
								</label>
							</div>
						</div>
					</div>
					<span className="request-list">All tickets </span>
					<div className="d-flex">
						<div
							className={`stats-holder ${status === "open" ? "active" : ""}`}
							onClick={() => statusChangeHandler("open")}
						>
							<label className="title">Open</label>
							<p className="value">{getCountForStatus("open")}</p>
						</div>
						<div
							className={`stats-holder ${
								status === "inprogress" ? "active" : ""
							}`}
							onClick={() => statusChangeHandler("inprogress")}
						>
							<label className="title">In-Progress</label>
							<p className="value">{getCountForStatus("inprogress")}</p>
						</div>
						<div
							className={`stats-holder ${status === "onhold" ? "active" : ""}`}
							onClick={() => statusChangeHandler("onhold")}
						>
							<label className="title">On-hold</label>
							<p className="value">{getCountForStatus("onhold")}</p>
						</div>
						<div
							className={`stats-holder ${status === "solved" ? "active" : ""}`}
							onClick={() => statusChangeHandler("solved")}
						>
							<label className="title">Solved</label>
							<p className="value">{getCountForStatus("solved")}</p>
						</div>
						<div
							className={`stats-holder ${
								status === "rejected" ? "active" : ""
							}`}
							onClick={() => statusChangeHandler("rejected")}
						>
							<label className="title">Rejected</label>
							<p className="value">{getCountForStatus("rejected")}</p>
						</div>
					</div>
					{assignedTickets?.length > 0 ? (
						<>
							<AssignedTicketTable tickets={tickets} />
							{isLoadMoreAssignedTickets ? (
								<div className="d-flex justify-content-center load-more-container">
									<button
										className="load-more"
										onClick={() => {
											const offsetParam = searchParams.get("offset");
											const offsetValue = offsetParam ? +offsetParam : 0;
											searchParams.set("offset", (offsetValue + 1).toString());
											searchParams.delete("redirect");
											setSearchParams(searchParams);
										}}
									>
										<span className="load-more-text">Load more</span>
									</button>
								</div>
							) : null}
						</>
					) : (
						<div className="d-flex flex-column align-items-center">
							<img
								style={{ marginTop: "30px", width: "300px", height: "300px" }}
								src="/NoTickets.svg"
							/>
							<p className="no-ticket">
								Currently, there are no tickets in queue.
							</p>
						</div>
					)}
				</>
			)}{" "}
		</>
	);
};

export default AssignedTickets;
